import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import { CurrentPlaylistProvider } from "./contexts/CurrentPlaylistContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { TermsProvider } from "./contexts/TermsContext";
import reportWebVitals from "./reportWebVitals";
import "./styles.css";
import Footer from "./components/Footer";
import Terms from "./components/Terms";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <TermsProvider>
      <ThemeProvider>
        <CurrentPlaylistProvider>
          <App />
          <Footer />
        </CurrentPlaylistProvider>
      </ThemeProvider>
    </TermsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
