import React, { useState } from "react";
import playlists from "../services/playlists";

const PlaylistTile = (props) => {
  const style =
    props.currentVideoIndex === props.index
      ? "playlist-item active"
      : "playlist-item";

  const isActiveSong = props.currentVideoIndex === props.index;

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
    // Do something when the div is hovered
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    // Do something when the mouse leaves the div
  };

  const onTileClickHandler = () => {
    props.onClick(props.video);
  };

  const icon =
    isHover || isActiveSong ? "fa-solid fa-play" : "fa-solid fa-music";

  return (
    <div
      className={style}
      onClick={onTileClickHandler}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="playlist-tile-wrapper">
        {<i className={icon}></i>}
        <div className="playlist-tile-text">
          {props.showArtist
            ? props.video.artist + " - " + props.video.title
            : props.video.title}
        </div>
      </div>
    </div>
  );
};

export default PlaylistTile;
