import React, { useState, useEffect, createContext } from "react";

export const CurrentPlaylistContext = createContext();

export const CurrentPlaylistProvider = ({ children }) => {
  const [currentPlaylist, setCurrentPlaylist] = useState(
    parseInt(localStorage.getItem("currentPlaylist")) || null
  );
  const [currentVideoIndex, setCurrentVideoIndex] = useState(
    parseInt(localStorage.getItem("currentVideoIndex")) || 0
  );
  const [artists, setArtists] = useState([]); // State to store the fetched data
  const [videos, setVideos] = useState([]); // State to store the fetched data
  const [isErrorLoadingDB, setIsErrorLoadingDB] = useState(false);
  const [isLoadingDB, setIsLoadingDB] = useState(true);

  /* useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await fetch("/api/playlists/");
        const data = await response.json();
        console.log(data);
        setArtists(data);
        setIsLoadingDB(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    Promise.resolve(fetchData()).catch((error) => {
      console.log("Error loading DB");
      setIsErrorLoadingDB(true);
    });
    // Call the function to fetch data
    //fetchData();
  }, []); // Dependency array */

  const setSearchVideoByID = (playlist_id, id) => {
    if (currentPlaylist === null) return;
    //console.log("setSearchVideoByID");
    //console.log(playlist_id);
    //console.log(id);
    const fetchData = async () => {
      try {
        const response = await fetch(`api/playlists/${playlist_id}/videos/`);
        const data = await response.json();
        //console.log(data);
        setVideos(data);
        const video = data.find((video) => video.id === id);
        setCurrentPlaylist(playlist_id);
        setCurrentVideoIndex(data.indexOf(video));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    // Call the function to fetch data
    fetchData();
  };

  const searchVideo = (playlist, video) => {
    setSearchVideoByID(playlist, video);
  };

  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const response = await Promise.race([
          fetch(`/api/playlists/`),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("timeout")), 5000)
          ),
        ]);
        const data = await response.json();
        //console.log(data);
        data.sort((a, b) => {
          const titleA = a.name.toUpperCase(); // Convert titles to uppercase for case-insensitive comparison
          const titleB = b.name.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;
        });
        setArtists(data);
        setIsLoadingDB(false);
      } catch (error) {
        setIsErrorLoadingDB(true);
        console.error("Error fetching data: ", error);
      }
    };
    // Call the function to fetch data
    fetchData();
  }, []); // Dependency array

  useEffect(() => {
    // Function to fetch data
    if (currentPlaylist === null) return;
    const fetchData = async () => {
      try {
        const response = await fetch(
          `api/playlists/${currentPlaylist}/videos/`
        );
        const data = await response.json();
        //console.log(data);
        setVideos(data);
      } catch (error) {
        //console.error("Error fetching data: ", error);
      }
    };

    // Call the function to fetch data
    fetchData();
  }, [currentPlaylist]); // Dependency array

  useEffect(() => {
    localStorage.setItem("currentPlaylist", currentPlaylist);
    localStorage.setItem("currentVideoIndex", currentVideoIndex);
  }, [currentPlaylist, currentVideoIndex]);

  return (
    <CurrentPlaylistContext.Provider
      value={{
        currentPlaylist,
        setCurrentPlaylist,
        currentVideoIndex,
        setCurrentVideoIndex,
        searchVideo,
        artists,
        videos,
        isLoadingDB,
        isErrorLoadingDB,
      }}
    >
      {children}
    </CurrentPlaylistContext.Provider>
  );
};

export default CurrentPlaylistContext;
