// PlaylistSidebar.js
import React, { useState, useContext, useEffect } from "react";
import PlaylistTile from "./PlaylistTile";
//import playlists from "../services/playlists";

import { motion } from "framer-motion";

import { CurrentPlaylistContext } from "../contexts/CurrentPlaylistContext"; // Import the CurrentPlaylistContext

const Playlist = () => {
  // Access the current playlist context
  const {
    currentPlaylist,
    setCurrentPlaylist,
    currentVideoIndex,
    setCurrentVideoIndex,
    artists,
    videos,
  } = useContext(CurrentPlaylistContext);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="playlist"
      >
        <div className="playlist-header">Seznam skladeb</div>
        <hr></hr>
        <motion.div className="playlist-group">
          {videos.map((video, index) => (
            <PlaylistTile
              key={index}
              video={video}
              index={videos.indexOf(video)}
              currentPlaylist={currentPlaylist}
              currentVideoIndex={currentVideoIndex}
              onClick={() => setCurrentVideoIndex(index)}
            />
          ))}
        </motion.div>
      </motion.div>
    </>
  );
};

export default Playlist;
