import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";

// Define themes with different color sets
export const themes = {
  blue: {
    "--primary": "#219ebc",
    "--secondary": "#46b8d4",
  },
  yellow: {
    "--primary": "#e1a817",
    "--secondary": "#c4920b",
  },
  red: {
    "--primary": "#e63946",
    "--secondary": "#f66d80",
  },
  purple: {
    "--primary": "#6a0572",
    "--secondary": "#9d4edd",
  },
  green: {
    "--primary": "#2b9348",
    "--secondary": "#3da35d",
  },
  pink: {
    "--primary": "#de5794",
    "--secondary": "#ff8bbd",
  },
  // Add more themes if needed
};

// Create a context with default theme
export const ThemeContext = createContext(themes.blue);

// Create a provider component to wrap your app and set the theme
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.blue);

  useLayoutEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      updateTheme(currentTheme);
    } else {
      updateTheme("blue");
    }
  }, []);

  // Function to update the theme
  const updateTheme = (newTheme) => {
    switch (newTheme) {
      case "blue":
        setTheme(themes.blue);
        localStorage.setItem("theme", "blue");
        break;
      case "yellow":
        setTheme(themes.yellow);
        localStorage.setItem("theme", "yellow");
        break;
      case "red":
        setTheme(themes.red);
        localStorage.setItem("theme", "red");
        break;
      case "green":
        setTheme(themes.green);
        localStorage.setItem("theme", "green");
        break;
      case "purple":
        setTheme(themes.purple);
        localStorage.setItem("theme", "purple");
        break;
      case "pink":
        setTheme(themes.pink);
        localStorage.setItem("theme", "pink");
        break;

      // Add more cases if needed
      default:
        setTheme(themes.blue);
        break;
    }
  };
  return (
    <ThemeContext.Provider value={{ theme, updateTheme }}>
      <div style={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};

// Custom hook to simplify accessing the context
export const useTheme = () => {
  return useContext(ThemeContext);
};
