import MyNavbar from "./components/MyNavbar2";
import { CurrentPlaylistContext } from "./contexts/CurrentPlaylistContext"; // Import the CurrentPlaylistContext
import { ThemeContext, ThemeProvider, themes } from "./contexts/ThemeContext";
import CatalogCard from "./components/CatalogCard";
import Player from "./components/Player";
import ModalCatalog from "./components/ModalCatalog";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect, useContext } from "react";
import LoadingCard from "./components/LoadingCard";
import SearchComponent from "./components/SearchComponent";
import Footer from "./components/Footer";
import Terms from "./components/Terms";

const App = () => {
  //get current playlist context
  const {
    currentPlaylist,
    setCurrentPlaylist,
    currentVideoIndex,
    setCurrentVideoIndex,
    artists,
    videos,
    isLoadingDB,
    isErrorLoadingDB,
  } = useContext(CurrentPlaylistContext);

  const { theme, updateTheme } = useContext(ThemeContext);

  useEffect(() => {
    // Function to fetch data
    if (currentPlaylist != null) {
      setShowCatalog(false);
      setShowPlayer(true);
    }
  }, []); // Dependency arrayu

  const [showModal, setShowModal] = useState(false);
  const [showCatalog, setShowCatalog] = useState(true);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const onPlaylistSelected = () => {
    setShowCatalog(false);
    setShowPlayer(true);
  };

  const onHomeClick = () => {
    setShowCatalog(true);
    setShowPlayer(false);
    setCurrentPlaylist(null);
    setCurrentVideoIndex(0);
  };

  const onThemeChange = () => {
    updateTheme(themes.yellow);
  };

  useEffect(() => {
    console.log("❤️ Jana");
  }, []);

  if ("serviceWorker" in navigator && "beforeinstallprompt" in window) {
    // Register the service worker
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });

    // Add an event listener to detect the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the default behavior to prevent automatic prompt
      event.preventDefault();

      // Save the event for later use
      const deferredPrompt = event;

      // Show your own custom install button or notification
      // Example: display an "Add to Home Screen" button
      const installButton = document.getElementById("install-button");
      console.log(installButton);
      if (installButton) {
        installButton.style.display = "block";

        // Add a click event listener to trigger the install prompt
        installButton.addEventListener("click", () => {
          // Trigger the deferredPrompt to show the install prompt
          console.log("👍", "butInstall-clicked");
          deferredPrompt.prompt();

          // Wait for the user to respond to the prompt
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
              console.log("User accepted the install prompt");
            } else {
              console.log("User dismissed the install prompt");
            }

            // Reset the deferredPrompt variable
            deferredPrompt = null;
          });
        });
      }
    });
  }

  return (
    <div className="App">
      <MyNavbar
        onHome={onHomeClick}
        onShowCatalog={handleShowModal}
        isCatalogVisible={showCatalog}
      />

      <div className="container-main">
        <SearchComponent onSongSelected={onPlaylistSelected} />
        {/* {!showCatalog && (
          <Button className="btn-moje" onClick={handleShowModal}>
            Katalog interpretů
          </Button>
        )} */}

        <ModalCatalog show={showModal} handleClose={handleCloseModal}>
          <CatalogCard />
        </ModalCatalog>
        {isLoadingDB ? <LoadingCard isError={isErrorLoadingDB} /> : ""}

        {!isLoadingDB && showCatalog && (
          <div className="container-catalog">
            <CatalogCard handleClose={onPlaylistSelected} />
          </div>
        )}

        {/*  <Button id="install-button" className="btn-moje">
          Instalovat
        </Button> */}

        {showPlayer && !isLoadingDB && <Player />}

        <Terms />
      </div>
    </div>
  );
};

export default App;
