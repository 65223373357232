import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { motion } from "framer-motion";

import "./Navbar.css";

const MyNavbar = (props) => {
  const [secondLine, setSecondLine] = useState("");

  const [citat, setCitat] = useState({
    "biblicky-citat-text": "",
    "biblicky-citat-citace": "",
  });

  useEffect(() => {
    fetch("https://zvukyviry.cz/api/citat-dne")
      .then((response) => response.json())
      .then((data) => setCitat(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <Navbar className="navbar" bg="bg-moje" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#" onClick={props.onHome}>
          <div className="logo-wrapper">
            <div className="navbar-dove">
              <i className="fas fa-dove"></i>
            </div>
            <div className="navbar-tone">
              <i className="fas fa-music"></i>
            </div>
          </div>
          <div className="text">Zvuky Víry</div>
          <div className="text"></div>
        </Navbar.Brand>

        <Navbar.Text className="navbar-text">
          {!props.isCatalogVisible && (
            <Button
              className="btn-moje btn-navbar"
              onClick={props.onShowCatalog}
            >
              Katalog interpretů
            </Button>
          )}
        </Navbar.Text>
        <div></div>

        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 2, delay: 1 }}
          className="navbar-text navbar-citat-wrapper"
          style={{ width: "40%" }}
        >
          <a
            href="https://www.vira.cz/"
            target="_blank"
            aria-label="Citát dne z webu Vira.cz"
          >
            <div className="citat-text">{citat["biblicky-citat-text"]}</div>
            <div className="citat-autor">{citat["biblicky-citat-citace"]}</div>
            {/* <div className="citat-text">Hospodin chvalte navěky</div>
            <div className="citat-autor">Mt 3,41</div> */}
          </a>
        </motion.div>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
