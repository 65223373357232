import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { TermsContext } from "../contexts/TermsContext";

const Terms = () => {
  const { handleCloseTerms, setShowTerms, terms } = useContext(TermsContext);

  const onClose = () => handleCloseTerms();

  return (
    <Modal show={terms} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          <h1>Podmínky používání</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Include your Terms of Usage text here */}

        <h2>1 Obsah webových stránek</h2>

        <p>1.1 Hudební katalog a playlisty</p>
        <p>
          Naše webové stránky poskytují prehrávač s katalogem hudebních skupin
          obsahující videa z YouTube. Odkazy na videa jsou vloženy pomocí
          embedded iframu, což umožňuje přehrávání videí přímo na našich
          webových stránkách.
        </p>

        <p>1.2 Odpovědnost za obsah</p>
        <p>
          Neneseme odpovědnost za obsah videí, které jsou obsaženy v našem
          přehrávači. Videa jsou připojena pomocí odkazů na YouTube, a tím pádem
          podléhají podmínkám služby YouTube.
        </p>

        <h2>2 Používání přehrávače a playlistů</h2>

        <p>2.1 Embedded iframe</p>
        <p>
          Pro přehrávání videí na našich webových stránkách využíváme
          technologii embedded iframu poskytovanou YouTube. Používáním této
          funkce souhlasíte s podmínkami služby YouTube.
        </p>

        <p>2.2 Omezení používání</p>
        <p>
          Nepovolujeme jakékoliv nelegální nebo neetické používání našeho
          přehrávače.
        </p>

        <h2>3 Ochrana osobních údajů</h2>

        <p>3.1 Žádné shromažďování údajů</p>
        <p>
          Na našem serveru neuchováváme o návštěvnících žádné osobní údaje. Pro
          provoz webu používáme pouze funkční cookies. Tyto cookies jsou pro
          provoz webu nezbytné a neslouží pro sledování návštěvníků webu.
        </p>

        <h2>Změny v podmínkách</h2>

        <p>
          Máme právo aktualizovat nebo změnit tyto podmínky kdykoliv bez
          předchozího upozornění. Doporučujeme pravidelně kontrolovat tuto
          stránku pro zjištění aktuální verze podmínek.
        </p>

        <p>
          Děkujeme za respektování těchto podmínek. Přejeme vám příjemný pobyt
          na našem webu.
        </p>

        <p className="info-aktualizace">
          Datum poslední aktualizace: 5.11.2023
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={onClose}>
          Zavřít
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Terms;
