import React, { useState, useEffect, useContext } from "react";
//import playlists from "../services/playlists.js";
import ArtistTile from "./ArtistTile.js";
import { CurrentPlaylistContext } from "../contexts/CurrentPlaylistContext"; // Import the CurrentPlaylistContext

const CatalogCard = (props) => {
  const {
    currentPlaylist,
    setCurrentPlaylist,
    currentVideoIndex,
    setCurrentVideoIndex,
    artists,
    videos,
  } = useContext(CurrentPlaylistContext);

  /* const [playlists, setPlaylists] = useState([]); // Add a state for the playlists

  useEffect(() => {
    console.log("Fetching playlists");
    fetch("/api/playlists/") // Replace with your database URL
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPlaylists(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []); // The empty array means this useEffect will run once when the component mounts
 */
  const setPlaylist = (id) => {
    setCurrentPlaylist(id);
    props.handleClose();
  };

  return (
    <>
      <div className="container">
        <div className="row">
          {artists
            ? artists.map((artist, index) => (
                <ArtistTile
                  key={index}
                  artist={artist}
                  onClick={() => setPlaylist(artist.id)}
                />
              ))
            : "Loading..."}
        </div>
      </div>
    </>
  );
};

export default CatalogCard;
