import React from "react";

const ArtistTile = (props) => {
  const imagePath = require(`../assets/playlists/${props.artist.logo}`);

  return (
    <div className="col-md-3 col-sm-12 tile-wrapper">
      <div className="playlist-tile" onClick={props.onClick}>
        <img
          className="playlist-image"
          src={imagePath}
          alt={props.artist.logo}
        />
        <div className="playlist-title">{props.artist.name}</div>
      </div>
    </div>
  );
};

export default ArtistTile;
