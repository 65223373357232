import React, { createContext, useState } from "react";

export const TermsContext = createContext();

export const TermsProvider = ({ children }) => {
  const [terms, setTerms] = useState(false);

  const handleCloseTerms = () => {
    setTerms(false);
  };

  const setShowTerms = () => {
    setTerms(true);
  };

  return (
    <TermsContext.Provider value={{ terms, setShowTerms, handleCloseTerms }}>
      {children}
    </TermsContext.Provider>
  );
};
