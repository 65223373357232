import React, { useState } from "react";

const ArtistTileCatalog = (props) => {
  const [isHover, setIsHover] = useState(false);
  const imagePath = require(`../assets/playlists/${props.artist.logo}`);

  const handleMouseEnter = () => {
    setIsHover(true);
    // Do something when the div is hovered
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    // Do something when the mouse leaves the div
  };

  const onTileClickHandler = () => {
    props.onClick(props.artist);
  };

  return (
    <div className="searchbar-artist" onClick={onTileClickHandler}>
      <div className="playlist-tile-wrapper">
        <img
          className="searchbar-image"
          src={imagePath}
          alt={props.artist.logo}
        />
        <div className="playlist-tile-text">{props.artist.name}</div>
      </div>
    </div>
  );
};

export default ArtistTileCatalog;
