import React, { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import { ReactSVG } from "react-svg";

import logo from "../assets/my-loader.svg";

const LoadingSpinner = () => {
  const { theme } = useContext(ThemeContext);
  const primaryColor = theme["--primary"];

  return (
    <>
      <img src={logo} alt="loading" />
    </>
  );
};

export default LoadingSpinner;
