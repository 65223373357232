import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import { motion } from "framer-motion";

const LoadingCard = (props) => {
  return (
    <div className="loading-card">
      {!props.isError ? (
        <>
          <div>
            <LoadingSpinner />
          </div>
          <div className="loading-text">Načítání databáze</div>
        </>
      ) : (
        <motion.div layout className="error-message">
          <i class="fa-regular fa-face-sad-tear"></i>
          <p>Nepodařilo se připojit k databázi.</p>
          <p>Zkuste to prosím později.</p>
        </motion.div>
      )}
    </div>
  );
};

export default LoadingCard;
