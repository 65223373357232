import React from "react";
import { motion } from "framer-motion";

const Links = (props) => {
  console.log(props.artist);
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5 }}
      exit={{ opacity: 0 }}
      className="container-links"
    >
      {props.artist?.www_link.length > 0 && (
        <a
          href={props.artist.www_link}
          target="_blank"
          rel="noreferrer"
          alt="Web"
        >
          <i className="fa-solid fa-globe globe"></i>
        </a>
      )}
      {props.artist?.fb_link.length > 0 && (
        <a
          href={props.artist.fb_link}
          target="_blank"
          rel="noreferrer"
          alt="Facebook"
        >
          <i className="fa-brands fa-facebook fb"></i>
        </a>
      )}
      {props.artist?.yt_link.length > 0 && (
        <a
          href={props.artist.yt_link}
          target="_blank"
          rel="noreferrer"
          alt="Youtube"
        >
          <i className="fa-brands fa-youtube yt"></i>
        </a>
      )}
    </motion.div>
  );
};

export default Links;
