import React, { useState, useContext } from "react";
import { Dropdown, Button } from "react-bootstrap";

import { ThemeContext, themes } from "../contexts/ThemeContext";
import { TermsContext } from "../contexts/TermsContext";

const Footer = () => {
  const { theme, updateTheme } = useContext(ThemeContext);
  const { setShowTerms, handleCloseTerms, terms } = useContext(TermsContext);

  const handleSelect = (eventKey) => {
    updateTheme(eventKey);
  };

  const showTermsModal = (event) => {
    event.preventDefault();
    setShowTerms();
  };

  return (
    <div className="footer">
      <div className="theme-button">
        <Dropdown onSelect={handleSelect} drop="up">
          <Dropdown.Toggle
            variant="--primary"
            className="btn-moje"
            id="dropdown-basic"
          >
            <i className="fa-solid fa-palette"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="blue">
              <span
                class="dot"
                style={{ backgroundColor: themes.blue["--primary"] }}
              ></span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="yellow">
              {" "}
              <span
                class="dot"
                style={{ backgroundColor: themes.yellow["--primary"] }}
              ></span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="red">
              {" "}
              <span
                class="dot"
                style={{ backgroundColor: themes.red["--primary"] }}
              ></span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="pink">
              {" "}
              <span
                class="dot"
                style={{ backgroundColor: themes.pink["--primary"] }}
              ></span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="green">
              {" "}
              <span
                class="dot"
                style={{ backgroundColor: themes.green["--primary"] }}
              ></span>
            </Dropdown.Item>
            <Dropdown.Item eventKey="purple">
              {" "}
              <span
                class="dot"
                style={{ backgroundColor: themes.purple["--primary"] }}
              ></span>
            </Dropdown.Item>
            {/* Add more items for other themes */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="copyright">
        <span className="terms">
          <a href="" alt="podminky" onClick={showTermsModal}>
            <i class="fa-solid fa-circle-info"></i>
          </a>
        </span>
        JV &copy; 2024
      </div>
    </div>
  );
};

export default Footer;
