// SearchComponent.js (React)

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import playlists from "../services/playlists";
import PlaylistTile from "./PlaylistTile";

import CurrentPlaylistContext from "../contexts/CurrentPlaylistContext";
import ArtistTileCatalog from "./ArtistTileCatalog";

const SearchComponent = (props) => {
  const [query, setQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([
    { playlists: [], videos: [] },
  ]);
  const [isSearching, setIsSearching] = useState(false);

  const {
    currentPlaylist,
    setCurrentPlaylist,
    currentVideoIndex,
    setCurrentVideoIndex,
    searchVideo,
  } = useContext(CurrentPlaylistContext);

  const debounce = (fn, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const handleSearch = debounce(async () => {
    try {
      setIsSearching(true);
      const searchTherm = { searchTerm: query };
      const response = await axios.post("/api/search", {
        searchTerm: searchTerm,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsSearching(false);
    }
  }, 300); // Adjust the debounce delay as needed

  useEffect(() => {
    if (searchTerm.length < 3) {
      setSearchResults([{ playlists: [], videos: [] }]);
      return;
    }
    handleSearch();
  }, [searchTerm]);

  const onTileClickHandler = (video) => {
    console.log(video);
    if (currentPlaylist != video.playlist_id) {
      setCurrentPlaylist(video.playlist_id);
      searchVideo(video.playlist_id, video.id);
    } else searchVideo(video.playlist_id, video.id);
    setSearchTerm("");
    setSearchResults([]);
    props.onSongSelected();
  };

  const onArtistTileClickHandler = (artist) => {
    console.log(artist);
    if (currentPlaylist != artist.id) {
      setCurrentPlaylist(artist.id);
      searchVideo(artist.id, artist.id);
    } else searchVideo(artist.id, artist.id);
  };

  return (
    <div className="search-bar">
      {/* {isSearching && <p>Searching...</p>} */}
      {/* {searchResults.map((video) => (
          <li key={video.id}>{video.title}</li>
        ))} */}

      <div className="container-search">
        <form className="form-inline search-bar-field">
          <input
            className="form-control mr-sm-2 search-bar"
            type="search"
            value={searchTerm}
            placeholder="Zadejte název interpreta nebo skladby"
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search"
          />
        </form>
        <div className="results">
          <ul>
            {searchTerm.length > 2 &&
              searchResults.playlists?.length > 0 &&
              searchResults.playlists?.map((artist, index) => (
                <li key={artist.id}>
                  <ArtistTileCatalog
                    key={index}
                    artist={artist}
                    onClick={onArtistTileClickHandler}
                  />
                </li>
              ))}
            {searchTerm.length > 2 &&
              searchResults.videos?.length > 0 &&
              searchResults.videos?.map((video, index) => (
                <li key={video.id}>
                  <PlaylistTile
                    key={index}
                    video={video}
                    showArtist={true}
                    index={video.id}
                    onClick={onTileClickHandler}
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
