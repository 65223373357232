import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import YouTube from "react-youtube";
import { ProgressBar } from "react-bootstrap";
//import playlists from "../services/playlists";
import { CurrentPlaylistContext } from "../contexts/CurrentPlaylistContext"; // Import the CurrentPlaylistContext
import Playlist from "./Playlist";
import { clear } from "@testing-library/user-event/dist/clear";
import { motion } from "framer-motion";
import Links from "./Links";

const Player = () => {
  const [player, setPlayer] = useState(null);
  const [songInfo, setSongInfo] = useState({});
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingOrPaused, setIsPlayingOrPaused] = useState(false);
  const [isPlayerVisible, setPlayerVisible] = useState(true);
  const [isPlaylistVisible, setPlaylistVisible] = useState(false);
  const [transitionEnd, setTransitionEnd] = useState(false);
  const [isPlayerError, setIsPlayerError] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isScheduledSkip, setIsScheduledSkip] = useState(false);
  const [isForcedSkip, setIsForcedSkip] = useState(false);
  const [forcedSkipTimeoutID, setForcedSkipTimeoutID] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isAnimationComplete, setisAnimationComplete] = useState(false);
  const [duration, setDuration] = useState({
    duration: 0,
    durationformattedTime: "00:00",
  });

  const [ytopts, setOpts] = useState({
    height: "315",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  });

  const handleTransitionEnd = () => {
    setTransitionEnd(true);
  };

  const artistHasSomeLinks = () => {
    const artist = artists.find((artist) => artist.id == currentPlaylist);
    return (
      artist.www_link?.length > 0 || // Check if the artist has a website
      artist.fb_link?.length > 0 || // Check if the artist has a Facebook page
      artist.yt_link?.length > 0 // Check if the artist has a YouTube channel
    );
  };

  const fillStyle = {
    backgroundColor: "#e1a817", // Customize the fill color
  };
  // Define refs for storing previous values

  // Access the current playlist context
  const {
    currentPlaylist,
    setCurrentPlaylist,
    currentVideoIndex,
    setCurrentVideoIndex,
    artists,
    videos,
  } = useContext(CurrentPlaylistContext);

  const prevCurrentVideoIndex = useRef(currentVideoIndex);
  const prevCurrentPlaylist = useRef(currentPlaylist);

  /*   useEffect(() => {
    setCurrentVideoIndex(0);
  }, [currentPlaylist]);

  useEffect(() => {
    updateSongInfo(playlists[currentPlaylist].videos[currentVideoIndex]);
  }, [currentVideoIndex, currentPlaylist]); */

  const toggleVisibility = () => {
    setPlayerVisible(!isPlayerVisible);
    setPlaylistVisible(!isPlaylistVisible);
    setTransitionEnd(false);
    const opts = {
      height: { isPlayerVisible } ? "315" : "1px",
      width: { isPlayerVisible } ? "100%" : "1px",
      playerVars: {
        autoplay: 0,
      },
    };
    setOpts(opts);
  };

  useLayoutEffect(() => {
    //console.log(currentPlaylist);
    //console.log(currentVideoIndex);
    //console.log(videos);
  }, []);
  // This effect runs when currentPlaylist changes
  useEffect(() => {
    if (currentPlaylist !== prevCurrentPlaylist.current) {
      setCurrentVideoIndex(0);

      prevCurrentPlaylist.current = currentPlaylist; // Update the ref
    }
  }, [currentPlaylist]);

  useEffect(() => {
    if (videos.length > 0)
      updateSongInfo(videos[currentVideoIndex >= 0 ? currentVideoIndex : 0]);
  }, [videos]);

  // This effect runs when currentVideoIndex or currentPlaylist changes
  useEffect(() => {
    if (videos.length > 0) {
      updateSongInfo(videos[currentVideoIndex]);
    }
  }, [currentVideoIndex]);

  const onPlayerReady = (event) => {
    // The player is ready
    setIsPlayerReady(true);
    setPlayer(event.target);

    playerRef.current = event.target; // Assign the player instance to playerRef

    // Get the duration of the video
    const duration = playerRef.current.getDuration();
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    const durationformattedTime = `${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    setDuration({ duration, durationformattedTime });
  };

  const onPlayerError = (event) => {
    // The video could not be loaded, e.g. because of a server error or
    // because the video is not embeddable
    console.log("onPlayerError");

    setIsPlayerError(true);
    setIsPlayerReady(false);
    setIsErrorVisible(true); // Show the error message
    playNextVideoForced();
    console.log(`YouTube Player Error: ${event.data}`);
    setTimeout(() => {
      //setIsPlayerReady(true);
      setIsErrorVisible(false); // Hide the error message after 3 seconds
    }, 3000);
  };

  const [time, setTime] = useState({ currentTime: 0, formattedTime: "00:00" });
  const playerRef = useRef(null); // Create a ref to store the player instance

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        playerRef.current &&
        playerRef.current.getPlayerState &&
        playerRef.current.getPlayerState() === window.YT.PlayerState.PLAYING
      ) {
        const currentTimeInSeconds = playerRef.current.getCurrentTime();
        const minutes = Math.floor(currentTimeInSeconds / 60);
        const seconds = Math.floor(currentTimeInSeconds % 60);
        const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
        setTime({ currentTime: currentTimeInSeconds, formattedTime });
      }
    }, 1000); // Update every second

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [time.currentTime]);

  /* useEffect(() => {
    const interval = setInterval(() => {
      console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
      console.log("Player state:", playerRef.current.getPlayerState());
      console.log("isPlayerReady:", isPlayerReady);
      console.log("isPlayerError:", isPlayerError);
      console.log("isPlaying:", isPlaying);
      console.log("isScheduledSkip:", isScheduledSkip);
      console.log("currentVideoIndex:", currentVideoIndex);
    }, 3000); // Log every 3 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [
    isPlayerReady,
    isPlayerError,
    isPlaying,
    isScheduledSkip,
    currentVideoIndex,
  ]); */

  const onPlayerStateChange = (event) => {
    console.log(event.data);
    // The player is not ready
    if (event.data === window.YT.PlayerState.BUFFERING) {
      setIsPlayerReady(false);
    }
    // The player is ready
    else if (event.data === window.YT.PlayerState.PLAYING) {
      setIsScheduledSkip(false);
      setIsPlayerError(false);
      setIsPlayerReady(true);
      setIsPlayingOrPaused(true);
      setIsPlaying(true);
    }
    //player ended
    else if (event.data === window.YT.PlayerState.ENDED) {
      playNextVideo();
      setIsPlaying(false);
    }
    //player paused
    else if (event.data === window.YT.PlayerState.PAUSED) {
      setIsPlayingOrPaused(true);
      setIsPlaying(false);
    } else if (event.data === window.YT.PlayerState.CUED) {
      setIsPlayingOrPaused(false);
      setIsPlaying(false);
    } else if (event.data === window.YT.PlayerState.UNSTARTED) {
      setIsPlayingOrPaused(false);
      setIsPlaying(false);
    } else if (event.data === window.YT.PlayerState.VIDEO_CUED) {
      setIsPlayingOrPaused(false);
      setIsPlaying(false);
    }
  };

  const playNextVideo = () => {
    const check =
      playerRef.current.getPlayerState() == 1 ||
      playerRef.current.getPlayerState() == 5;

    if (isForcedSkip) {
      setIsForcedSkip(false);
      clearTimeout(forcedSkipTimeoutID);
      setTimeout(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
      }, 100); // Delay of 1 second
    }

    if (isPlayerReady) {
      setIsErrorVisible(false);
      setIsScheduledSkip(true);

      setTimeout(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
        console.log("trying to play next video");
        setIsPlayerReady(false);
        setIsScheduledSkip(false);
      }, 100); // Delay of 1 second
    }
  };

  const playNextVideoForced = () => {
    setIsForcedSkip(true);
    setForcedSkipTimeoutID(
      setTimeout(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
        setIsPlayerReady(false);
        console.log("trying to play next video forced");
      }, 3000)
    ); // Delay of 1 second
  };

  const playPreviousVideo = () => {
    const check =
      playerRef.current.getPlayerState() == 1 ||
      playerRef.current.getPlayerState() == 5;
    if (isForcedSkip) {
      setIsForcedSkip(false);
      clearTimeout(forcedSkipTimeoutID);
      setTimeout(() => {
        setCurrentVideoIndex(
          (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
        );
      }, 100); // Delay of 1 second
    }
    if (isPlayerReady) {
      setIsErrorVisible(false);
      setIsPlayerReady(false);
      setTimeout(() => {
        setCurrentVideoIndex(
          (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
        );
      }, 100); // Delay of 1 second
    }
  };

  const playpauseVideo = () => {
    if (playerRef.current?.getPlayerState() === window.YT.PlayerState.PAUSED) {
      playerRef.current.playVideo();
      setIsPlaying(true);
    } else if (
      playerRef.current?.getPlayerState() === window.YT.PlayerState.PLAYING
    ) {
      playerRef.current?.pauseVideo();
      setIsPlaying(false);
    } else if (isPlayerReady) {
      playerRef.current.playVideo();
    }
  };

  const updateSongInfo = (video) => {
    console.log(video);
    if (video) {
      setSongInfo({
        title: video.title,
        artist: video.artist,
      });
      setTime({ currentTime: 0, formattedTime: "00:00" });
    }
  };

  const onComplete = () => {
    console.log("onComplete");
    setisAnimationComplete(true);
  };

  /* const handleProgressBarClick = (event) => {
    // Get the bounding rectangle of target
    const rect = event.target.getBoundingClientRect();

    // Calculate the new time for the video
    //give there some code to debug it better

    const clickPositionInPixels = event.clientX - rect.left;
    const newTime = (clickPositionInPixels / rect.width) * duration;

    // Seek to the new time
    if (player) {
      player.seekTo(newTime);
    }

    // Update the time state
  }; */

  const handleProgressBarClick = (event) => {
    // Get the bounding rectangle of the progress bar
    const rect = event.target.getBoundingClientRect();

    // Calculate the click position within the progress bar
    const clickPositionInPixels = event.clientX - rect.left;

    // Calculate the new time for the video
    const newTime = (clickPositionInPixels / rect.width) * duration.duration;

    // Seek to the new time
    playerRef.current.seekTo(newTime);

    // Update the time state
    const minutes = Math.floor(newTime / 60);
    const seconds = Math.floor(newTime % 60);
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    setTime({ currentTime: newTime, formattedTime });
  };
  const opts = {
    height: "315",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 0,
      fs: 0,
      rel: 0,
    },
  };

  const youtubeContainerStyle = {
    position: "relative",
    //pointerEvents: "none", // Set pointerEvents to 'none' to prevent interactions
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="card player-container"
    >
      <motion.div layout="position" className="sliding-container">
        {/* {isPlayerVisible ? (
          <div className="carousel-arrow left" onClick={playPreviousVideo}>
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        ) : (
          ""
        )} */}
        {!isErrorVisible ? (
          <div
            layout="position"
            className={`${!isPlayerVisible ? "hide" : "yt-container"}`}
            style={youtubeContainerStyle}
            href="/youtube"
          >
            {videos.length > 0 ? (
              <YouTube
                /* className={`${!isPlayerVisible && transitionEnd ? "hidden2" : ""} ${
            !isPlayerVisible && !transitionEnd ? "hidden2" : ""
          }`} */
                videoId={
                  videos[currentVideoIndex] && videos.length > 0
                    ? videos[currentVideoIndex].video_id
                    : videos[0].video_id
                }
                opts={opts}
                onReady={onPlayerReady}
                onStateChange={onPlayerStateChange}
                onError={onPlayerError}
              />
            ) : (
              ""
            )}
          </div>
        ) : isPlayerVisible ? (
          <div className="error-wrapper">
            <motion.div layout className="error-message">
              <i class="fa-regular fa-face-sad-tear"></i>
              <p>Omlouváme se,</p>
              <p>ale skladba není dostupná.</p>
            </motion.div>
          </div>
        ) : (
          ""
        )}
        {/*  {isPlayerVisible ? (
          <div
            className="carousel-arrow right"
            onClick={isPlayerReady ? playNextVideo : null}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        ) : (
          ""
        )} */}
        <motion.div
          layout="position"
          transition={{ layout: { duration: 0.5 } }}
          onAnimationComplete={onComplete}
          className="wrapper"
        >
          <motion.div layout="position" className="info-card">
            <div className="song-info-wrapper">
              <div className="artist-info">{songInfo.artist}</div>
              <div className="song-info">{songInfo.title}</div>
            </div>
            {/* <div className="song-current-time">
          {isPlayingOrPaused ? time.formattedTime : ""}
        </div> */}
            <div style={{ marginLeft: "10px" }}>
              <i
                className="playlist-button fa-solid fa-list"
                onClick={toggleVisibility}
              ></i>
            </div>
          </motion.div>
          <motion.div layout="position" className="progress-bar-wrapper">
            <div className="song-current-time">{time.formattedTime}</div>

            <ProgressBar
              variant="moje"
              className={`player-progress-bar ${
                isPlayerVisible ? "" : "slide"
              }`}
              now={playerRef.current ? playerRef.current.getCurrentTime() : 0}
              max={playerRef.current ? playerRef.current.getDuration() : 0}
              //label={time.formattedTime}
              onClick={handleProgressBarClick}
            />

            <div className="song-duration">
              {duration.durationformattedTime}
            </div>
          </motion.div>
          <div className="player-controls">
            <i
              className="fa-solid fa-backward-step skip"
              onClick={playPreviousVideo}
            ></i>
            {isPlaying ? (
              <i
                className="fa-solid fa-circle-pause"
                onClick={playpauseVideo}
              ></i>
            ) : (
              <i
                className="fa-solid fa-circle-play"
                onClick={playpauseVideo}
              ></i>
            )}

            <i
              className="fa-solid fa-forward-step skip"
              onClick={playNextVideo}
            ></i>
          </div>
        </motion.div>
        {isPlaylistVisible && artistHasSomeLinks() ? (
          <>
            {" "}
            <hr></hr>
            <Links
              artist={artists.find((artist) => artist.id == currentPlaylist)}
            />
            <hr></hr>
          </>
        ) : (
          ""
        )}
        {isPlaylistVisible ? <Playlist /> : ""}
      </motion.div>
    </motion.div>
  );
};

export default Player;
