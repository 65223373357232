import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";

import themes from "../contexts/ThemeContext";

function ModalCatalog(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Katalog interpretů</Modal.Title>
        <Button variant="light" onClick={props.handleClose}>
          <span aria-hidden="true">×</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        {" "}
        {React.Children.map(props.children, (child) => {
          // Pass the handleClose function as a prop to the child component
          return React.cloneElement(child, {
            handleClose: props.handleClose,
          });
        })}
      </Modal.Body>
      {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
    </Modal>
  );
}

export default ModalCatalog;
